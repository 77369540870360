import { Link } from 'gatsby'
import React from 'react'
import { resolvePath } from '../utils/dato'
import { FeatherIcon } from './FeatherIcon'

type Props = {
  title: string
  slug: string
  location: string
}

export const JobPosition: React.FC<Props> = ({ title, slug, location }) => {
  return (
    <Link to={resolvePath({ kind: 'job', slug })}>
      <div className="flex justify-between items-center bg-white px-5 py-4 rounded border-b-2 border-brand shadow-lg">
        <div className="mr-4 flex flex-col">
          <span className="font-bold">{title}</span>
          <span className="text-gray-700">{location}</span>
        </div>
        <FeatherIcon name="chevron-right" />
      </div>
    </Link>
  )
}
