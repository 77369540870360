import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { JobPosition } from '../components/JobPosition'
import { Markdown } from '../components/Markdown'
import { PageH1 } from '../components/PageH1'
import { WidthWrapper } from '../components/WidthWrapper'
import { CareersPageQuery } from '../types/graphql'
import { cx } from '../utils/helpers'

export const query = graphql`
  query CareersPageQuery {
    datoCmsCareersPage {
      seoMetaTags {
        tags
      }

      forewordNode {
        childMarkdownRemark {
          html
        }
      }

      openings {
        ... on DatoCmsJobPosition {
          title
          slug
          location
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

type Props = {
  data: CareersPageQuery
}

const CareersPage: React.FC<Props> = ({ data }) => {
  const { seoMetaTags, forewordNode, openings } = data.datoCmsCareersPage!

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        <Breadcrumb>
          <BreadcrumbItem>Careers</BreadcrumbItem>
        </Breadcrumb>

        <PageH1>Careers</PageH1>

        <WidthWrapper>
          <div className="lg:flex lg:my-8">
            <section className="my-8 lg:my-0 lg:w-1/2 lg:pr-8 text-lg">
              <div className="max-w-md">
                <Markdown
                  innerHTML={forewordNode!.childMarkdownRemark!.html!}
                />
              </div>
            </section>

            <section
              className={cx(
                'my-8 pt-8 border-t border-gray-200',
                'lg:my-0 lg:pt-0 lg:w-1/2 lg:pl-8 lg:border-l lg:border-t-0',
              )}
            >
              <div className="sm:flex lg:block">
                {openings && openings.length ? (
                  <ul className="sm:w-1/2 lg:w-full sm:-mt-8 lg:mt-0 max-w-xs sm:max-w-full">
                    {openings.map(jobPosition => (
                      <li key={jobPosition!.slug!} className="mt-4 sm:mt-8">
                        <JobPosition
                          title={jobPosition!.title!}
                          slug={jobPosition!.slug!}
                          location={jobPosition!.location!}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="mt-4 text-gray-600">
                    There is currently no job opening available. Please check
                    back from time to time.
                  </div>
                )}
              </div>
            </section>
          </div>
        </WidthWrapper>
      </main>
    </>
  )
}

export default CareersPage
